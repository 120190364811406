import React from "react";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { stringToSlug } from "../utils/stringManipulation";

const SpecialistsRow = (props) => {
  const specialist = props.specialists && props.specialists[0];

  return (
    <div className="sterea-center-column-wrapper">
      <div className="sterea-center-column py-12">
        {!props.noTitle && (
          <h2 className="text-center mb-12">Gli specialisti</h2>
        )}
        <div className="flex flex-wrap">
          {props.specialists?.map((specialist) => (
            <div className="sterea-specialist-card-wrapper w-full lg:w-1/2 flex px-min mb-12">
              <div className="w-full">
                <div className="sterea-specialist-card mb-6">
                  <div className="color-overlay absolute w-full h-full bg-sterea-dark-blue bg-opacity-10 rounded-md z-20"></div>
                  <GatsbyImage
                    image={getImage(specialist.Copertina?.localFile)}
                    style={{ position: "absolute" }}
                    className="w-full h-full z-10"
                    objectFit="cover"
                    alt={specialist.Copertina?.alternativeText}
                  />
                </div>
                <h3 className="font-bold text-3xl mb-6 text-center relative z-20">
                  {specialist.Titolo}
                </h3>
                {specialist.anteprima_testo && (
                  <p className="mt-3 text-center">{specialist.anteprima_testo}</p>
                )}
                {/* <Link
                  to={`/cliniche/${
                    (specialist.Permalink &&
                      specialist.Permalink != " " &&
                      specialist.Permalink) ||
                    stringToSlug(specialist.Titolo)
                  }`}
                  className="mt-3 flex items-center text-sterea-bright-blue"
                >
                  Scopri di più{" "}
                  <svg
                    className="ml-2"
                    width="29"
                    height="16"
                    viewBox="0 0 29 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M28.7071 8.70711C29.0976 8.31658 29.0976 7.68342 28.7071 7.29289L22.3431 0.928932C21.9526 0.538408 21.3195 0.538408 20.9289 0.928932C20.5384 1.31946 20.5384 1.95262 20.9289 2.34315L26.5858 8L20.9289 13.6569C20.5384 14.0474 20.5384 14.6805 20.9289 15.0711C21.3195 15.4616 21.9526 15.4616 22.3431 15.0711L28.7071 8.70711ZM0 9H28V7H0V9Z"
                      fill="#00D1FF"
                    />
                  </svg>
                </Link> */}
              </div>
            </div>
          ))}
        </div>
        {/* <div className="flex justify-center mt-12">
      <div className="sterea-blue-purple-button">
        Vedi tutte le destinazioni
      </div>
    </div> */}
      </div>
    </div>
  );
};

export default SpecialistsRow;
