import * as React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import NewsletterBanner from "../../components/newsletterBanner";
import SpecialistsRow from "../../components/specialistsRow";

import backgroundStillJpg from "../../images/hero_banner.jpg";
import { stringToSlug } from "../../utils/stringManipulation";

const query = graphql`
  query {
    allStrapiPage(filter: { sezione: { eq: "specialisti" } }) {
      nodes {
        id
        anteprima_testo
        Titolo
        Permalink
        Contenuto
        contenuto
        categoria {
          Name
        }
        publication_date
        posizione_copertina
        Copertina {
          alternativeText
          localFile {
            absolutePath
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                quality: 90
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  }
`;

const IndexPage = () => {
  const data = useStaticQuery(query);

  return (
    <Layout>
      <SEO title="Sterea Medicina e Viaggi" />

      <div className="sterea-center-column-wrapper overflow-hidden relative">
        <div className="sterea-center-column h-1/4-screen z-20 flex justify-center items-center">
          <h1 className="w-11/12 text-sterea-blue text-center text-3xl lg:text-4xl leading-normal font-bold">
            Gli specialisti
          </h1>
        </div>
      </div>

      {/* <div className="sterea-center-column-wrapper">
        <div className="sterea-center-column flex flex-wrap justify-center py-12 px-min -mb-3">
          <div className="sterea-blue-button mr-3 mb-3">
            <Link to="/blog/chirurgia-estetica">
              Chirurgia estetica
            </Link>
          </div>
          <div className="sterea-button bg-sterea-light-gray filter drop-shadow-sm mr-3 mb-3">Trapianto capelli</div>
          <div className="sterea-button bg-sterea-light-gray filter drop-shadow-sm mr-3 mb-3">Fertilità</div>
          <div className="sterea-button bg-sterea-light-gray filter drop-shadow-sm mr-3 mb-3">Odontoiatria</div>
          <div className="sterea-button bg-sterea-light-gray filter drop-shadow-sm mr-3 mb-3">Turismo medico</div>
          <div className="sterea-button bg-sterea-light-gray filter drop-shadow-sm mb-3">Generale</div>
        </div>
      </div> */}

      <SpecialistsRow noTitle={true} specialists={data.allStrapiPage.nodes} />

      <NewsletterBanner />
    </Layout>
  );
};

export default IndexPage;
