import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const NewsletterBanner = () => (
  <form
    name="newsletter"
    method="post"
    data-netlify="true"
    action="/grazie-newsletter"
  >
    <input type="hidden" name="form-name" value="newsletter" />
    <div className="sterea-center-column-wrapper overflow-hidden relative">
      <div className="color-overlay absolute w-full h-full bg-sterea-dark-blue bg-opacity-60 z-20"></div>
      <StaticImage
        quality={90}
        src="../images/newsletter_background.jpg"
        loading="eager"
        placeholder="blurred"
        layout="fullWidth"
        style={{ position: "absolute" }}
        className="w-full h-full z-10"
        objectFit="cover"
        alt="Immagine di Kaunas, Lituania, con in vista il fiume e il centro storico"
      />
      <div className="sterea-center-narrow-column h-96 z-20 px-min text-center flex flex-col justify-center">
        <h2 className="text-white font-bold mb-3">
          Iscriviti alla nostra newsletter
        </h2>
        <p className="text-white mb-9">
          Offerte riservate e le ultime notizie del settore, direttamente nella
          tua casella della posta.
        </p>
        <div className="flex">
          <input
            type="email"
            name="email"
            placeholder="Email"
            className="w-full bg-white p-3 outline-none rounded-l-md rounded-r-none"
            required
          />
          <input
            type="submit"
            value="Iscriviti"
            className="font-bold py-2 px-8 cursor-pointer bg-sterea-yellow text-white rounded-l-none rounded-r-md"
          />
        </div>
        <label className="flex mt-3">
          <input type="checkbox" name="accepted-privacy-policy" className="mr-3 w-12" required />
          <p className="text-xs text-left text-white">Confermo di aver letto e compreso L'INFORMATIVA SULLA PRIVACY di Sterea Medicina e Viaggi e acconsento al trattamento dei miei dati personali a scopi di marketing e di profilazione.</p>
        </label>
      </div>
    </div>
  </form>
);

export default NewsletterBanner;
